<template>
  <div class="f2f-schedule">
    <f2f-alert
      :message="getNotification.message"
      :display="getNotification.display"
      @hide="hideNotification"
    />
    <div class="f2f-schedule__container">
      <b-row>
        <b-col cols="12">
          <div class="f2f-schedule__head">
            <div class="ml-3">
              <div class="schedule-head__title">Classes</div>
              <div class="schedule-head__section">Schedule</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4 m-0">
        <b-col cols="9">
          <div class="f2f-schedule-container">
            <AddOrEditClass />
          </div>
        </b-col>
        <b-col cols="3" class="f2f-schedule-info">
          <div class="class-info__head">Classes</div>
          <div class="class-info__text">
            You can create a class to schedule a onsite event enroll and manage
            instructor's and students.
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import AddOrEditClass from "@/components/classes/schedule/AddOrEditClass.vue";

export default Vue.extend({
  components: { AddOrEditClass },
  computed: {
    ...mapGetters(["getNotification"])
  },
  data() {
    return {
      date: null,
      showAlert: false,
      isEdit: false,
      submitted: false,
      attachUnit: "0"
    };
  },
  methods: {
    hideNotification() {
      this.$store.dispatch("hideNotification");
    }
  }
});
</script>

<style lang="scss" scoped>
.f2f-schedule {
  .f2f-schedule__container {
    max-width: 1280px;
    min-width: 900px;
    margin: 0 auto;
    padding-bottom: 60px;
    .f2f-schedule__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0rem;
      border-bottom: 0.5px solid #e8e8e8;

      .schedule-head__title {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #4c4c4c;
      }

      .schedule-head__section {
        font-weight: bold;
        font-size: 38px;
        line-height: 32px;
        letter-spacing: 0.1px;
        color: #333333;
      }
    }
    .f2f-schedule-container {
      background: #ffffff;
      border: 1px solid #b2b2b2;
      box-sizing: border-box;
      padding: 2rem;
      .form-space {
        padding: 1rem;
      }
      .f2f-schedule-heading {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height, or 120% */

        letter-spacing: 0.1px;

        color: #4c4c4c;
      }
      .f2f-schedule-text {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 24px;
        /* or 150% */

        letter-spacing: 0.1px;

        /* Secondary text */

        color: #52575c;
      }

      .f2f-schedule-span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        /* or 133% */

        letter-spacing: 0.2px;

        /* Default texts in text field */

        color: #757575;
      }

      .f2f-validation-text {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 18px;
        /* identical to box height, or 164% */

        letter-spacing: 0.2px;

        /* Other/Negative */

        color: #ff4040;
      }
    }

    .f2f-schedule-info {
      .class-info__head {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #25282b;
        margin-bottom: 6px;
      }
      .class-info__text {
        font-family: Roboto;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #52575c;
      }
    }
  }
  .has-success {
    color: green;
  }
}
</style>
